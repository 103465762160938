import lottieWeb from 'lottie-web';

const muteBtn = document.querySelector('.js-mute-btn');
const backgroundVideo = document.querySelector('.js-background-video');
let muteState = 'mute';

const muteAnimation = lottieWeb.loadAnimation({
  container: muteBtn,
  path: 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/mute/mute.json',
  renderer: 'svg',
  loop: false,
  autoplay: false,
  name: "Mute Animation",
  initialSegment: [15, 25]
});

if (muteBtn) {
  muteBtn.addEventListener('click', handleMute);
}

function handleMute() {
  if (muteState === 'unmute') {
    muteAnimation.playSegments([0, 15], true);
    backgroundVideo.muted = true;
    muteState = 'mute';
  } else {
    muteAnimation.playSegments([15, 25], true);
    backgroundVideo.muted = false;
    muteState = 'unmute';
  }
}