import en from '../i18n/en.json'
import de from '../i18n/de.json'
import cs from '../i18n/cs.json'

let locale;
let translations = {
  en: { ...en },
  de: { ...de },
  cs: { ...cs },
};

document.addEventListener("DOMContentLoaded", () => {
  setLocale(detectLocale());
});


function setLocale(newLocale) {
  if (newLocale === locale) return;

  locale = newLocale;

  translatePage();
}

function translatePage() {
  document.querySelectorAll("[data-i18n-key]").forEach(translateElement);
}

function translateElement(element) {
  const key = element.getAttribute("data-i18n-key");
  const translation = translations[locale][key];

  element.innerHTML = translation;
}

function detectLocale() {
  const locale = browserLocale()
  return locale === 'cs' || locale === 'de' ? locale : 'en';
}

function browserLocale() {
  return (navigator.language || navigator.userLanguage).split("-")[0]
}

export { translations, locale }