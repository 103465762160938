const arrowUp = document.querySelector('.js-scroll-arrow');

window.addEventListener('scroll', handleArrowUpVisibility);
if (arrowUp) {
  arrowUp.addEventListener('click', handleScrollTop);
}

function handleArrowUpVisibility() {
  if (!arrowUp) {
    return;
  }

  if (window.scrollY > 700) {
    arrowUp.classList.add("nc-scroll-arrow--visible");
  } else {
    arrowUp.classList.remove("nc-scroll-arrow--visible");
  }
}

function handleScrollTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  history.pushState("", document.title, window.location.pathname + window.location.search);
}