const navCheckbox = document.querySelector('.js-nav-checkbox');
const navItems = document.querySelectorAll('.js-nav-item');

navItems.forEach(item => {
  item.addEventListener('click', e => {
    const anchor = e.currentTarget.dataset.anchor

    if (!anchor) {
      return
    }

    navCheckbox.checked = false
    handleBlockedScroll(navCheckbox.checked)

    if (window.outerWidth <= 992) {
      setTimeout(() => {
        scrollToAnchor(anchor)
      }, 400)
    } else {
      scrollToAnchor(anchor)
    }
  })
})

navCheckbox.addEventListener('input', e => {
  if (e.target.checked) {
    location.hash = ''
  }

  handleBlockedScroll(e.target.checked)
})

function handleBlockedScroll(checked) {
  if (checked) {
    document.body.classList.add("stop-scrolling");
  } else {
    document.body.classList.remove("stop-scrolling");
  }
}

function scrollToAnchor(anchor) {
  if (location.hash === anchor) {
    location.hash = ""
  }

  location.hash = anchor
}