import { translations, locale } from './i18n';

const reviews = document.querySelectorAll('.js-review');
const reviewBtns = document.querySelectorAll('.js-show-review');
let allReviewsClosed = true;

if (!!reviewBtns.length) {
  reviewBtns.forEach(btn => btn.addEventListener('click', handleShowReviewBtnClick));
}

function handleShowReviewBtnClick(e) {
  const review = e.target.parentElement.querySelector('.js-review');
  const isOpened = review.classList.contains('nc-review__text-wrap--open');
  const delay = allReviewsClosed ? 0 : 310;

  if (!allReviewsClosed) {
    closeBios();
  }

  if (isOpened) {
    return;
  }

  setTimeout(() => {
    review.classList.add('nc-review__text-wrap--open');
    e.target.textContent = translations[locale]["show-less"]

    allReviewsClosed = false
  }, delay);
}

function closeBios() {
  reviews.forEach(review => {
    review.classList.remove('nc-review__text-wrap--open');
  });

  reviewBtns.forEach(btn => {
    btn.textContent = translations[locale]["show-more"];
  });

  allReviewsClosed = true;
}