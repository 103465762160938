const rejectCookieBtn = document.querySelector('.js-reject-cookies');
const acceptCookieBtn = document.querySelector('.js-accept-cookies');
const cookieBar = document.querySelector('.nc-cookie-bar');

window.dataLayer = window.dataLayer || [];
gtag('consent', 'default', {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied'
});

window.addEventListener('load', handleCookieBar);
rejectCookieBtn.addEventListener('click', () => {
  setCookie('accept-cookies', 'false', 30);
  handleCookieBar()
})
acceptCookieBtn.addEventListener('click', () => {
  setCookie('accept-cookies', 'true', 30);
  handleCookieBar()
})

function handleCookieBar() {
  cookiesAccepted = getCookie('accept-cookies');

  if (cookiesAccepted === "") {
    cookieBar.classList.add('nc-cookie-bar--visible')
  } else if (cookiesAccepted === "false") {
    cookieBar.classList.remove('nc-cookie-bar--visible')
    setTimeout(() => {
      cookieBar.style.display = "none"
    }, 300);
  } else if (cookiesAccepted === "true") {
    cookieBar.classList.remove('nc-cookie-bar--visible')
    setTimeout(() => {
      cookieBar.style.display = "none"
    }, 300);

    gtag('js', new Date());
    gtag('config', 'G-D6KDEHGSKD');
    gtag('consent', 'update', {
      'analytics_storage': 'granted'
    });
  }
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

function gtag() {
  dataLayer.push(arguments);
}