import './sass/main.scss';
import './js/audio-player';
import './js/authors';
import './js/navigation';
import './js/i18n';
import './js/scroll';
import './js/reviews';
import './js/mute';
import './js/ga';

import './assets/audio/01.wav';
import './assets/audio/02.wav';
import './assets/audio/03.wav';
import './assets/audio/04.wav';
import './assets/audio/05.wav';
import './assets/audio/06.wav';
import './assets/audio/07.wav';
import './assets/audio/08.wav';
import './assets/audio/09.wav';
import './assets/audio/10.wav';
import './assets/audio/11.wav';
import './assets/audio/12.wav';
import './assets/audio/13.wav';
import './assets/audio/14.wav';
