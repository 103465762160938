import { init as initAudioPlayer, playState, playIconContainer, seekSlider, currentTimeContainer, calculateTime } from './audio-player';

const authorCards = document.querySelectorAll('.js-author-card');
const authorBtns = document.querySelectorAll('.js-author-image');
const audioPlayer = document.querySelector('.js-audio-player');
const audio = document.querySelector('.js-audio');
let allAuthorsClosed = false;

authorBtns.forEach(btn => btn.addEventListener('click', handleAuthorBtnClick));

function handleAuthorBtnClick(e) {
  const authorCard = e.currentTarget.parentElement
  const isOpened = authorCard.classList.contains('nc-author-card--active');
  const delay = allAuthorsClosed ? 0 : 300;

  if (!allAuthorsClosed) {
    closeCard();
  }

  if (isOpened) {
    return;
  }

  setTimeout(() => {
    authorCard.appendChild(audioPlayer);
    audio.src = `../assets/${authorCard.dataset.audio}.wav`;
    audioPlayer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`);

    initAudioPlayer();

    authorCard.classList.add('nc-author-card--active');
    allAuthorsClosed = false
  }, delay);
}

function closeCard() {
  const openedCard = document.querySelector('.js-author-card.nc-author-card--active');
  openedCard.classList.remove('nc-author-card--active');

  if (playState === "pause") {
    playIconContainer.click();
  }

  seekSlider.value = 0;
  currentTimeContainer.textContent = calculateTime(seekSlider.value);

  setTimeout(() => {
    if (!audioPlayer) {
      return;
    }

    openedCard.removeChild(audioPlayer);
  }, 300);

  allAuthorsClosed = true;
}